/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import ReactGA from 'react-ga';
import { SETTINGS } from './src/config';

switch(window.location.host) {
  case 'headwindapp.com':
    ReactGA.initialize(SETTINGS.ga.live);
  break;
  case 'development.headwindapp.com':
    ReactGA.initialize(SETTINGS.ga.dev);
  break;
  default:
    ReactGA.initialize(SETTINGS.ga.local, { debug: true });
}

export const onRouteUpdate = (state, page, pages) => {
  ReactGA.pageview(state.location.href);
};
